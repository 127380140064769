<template>
    <div class="box">
        <h2>
            预订单列表
            <div class="switchTab">
                <span v-for="item in orderStatusList" :key="item.value" :class="orderStatus == item.value ? 'act' : ''" class="tab-item" @click="getOrderList(item.value)">
                    {{ item.label }}
                    <span class="number">（{{ memberInfo[item.value] }}）</span>
                </span>
            </div>
        </h2>
        <div class="form-row">
            <div class="form-item" style="margin-right: 30px">
                <span>搜索订单</span>
                <el-input size="small" style="width: 150px" v-model="keyword" name="keyword" placeholder="请输入订单号"></el-input>
            </div>
<!--            <div class="form-item" style="margin-right: 30px">-->
<!--                <span>付款状态</span>-->
<!--                <el-select v-model="payStatus" name="payStatus" style="width: 120px" size="small" class="get-method" placeholder="所有">-->
<!--                    <el-option v-for="item in checkOrders" :key="item.value" :label="item.label" :value="item.value"> </el-option>-->
<!--                </el-select>-->
<!--            </div>-->
            <div class="form-item">
                <span>订单状态</span>
                <el-select v-model="orderStatus" name="orderStatus" style="width: 120px" size="small" class="get-method" placeholder="所有">
                    <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>

                <el-button
                    class="search-btn"
                    @click="
                        currenPage = 1
                        getOrderList()
                    "
                >
                    <img src="~img/alibaba/search.png" />搜索</el-button
                >
            </div>
        </div>
        <div class="table">
            <el-table :data="orderList" @select-all="selectAll" @selection-change="setCheck" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }" class="top1">
                <el-table-column type="selection" width="50"> </el-table-column>
                <el-table-column prop="order_no" label="订单号" align="center"> </el-table-column>
                <el-table-column label="下单时间" align="center">
                    <template slot-scope="scope">
                        {{ $util.timeStampTurnTime(scope.row.create_time) }}
                    </template>
                </el-table-column>
                <el-table-column prop="order_money" label="订单应付金额" align="center"> </el-table-column>
                <el-table-column prop="yijiaohuo_price" label="已交货金额" align="center"></el-table-column>
                <el-table-column prop="weijiaohuo_price" label="未交货金额" align="center"></el-table-column>
                <el-table-column label="订单状态" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.order_status == 5">未确认</span>
                        <span v-if="scope.row.order_status == 6">已确认|未完成</span>
                        <span v-if="scope.row.order_status == 7">已确认|未完成</span>
                        <span v-if="scope.row.order_status == 11">已确认|部分完成</span>
                        <span v-if="scope.row.order_status == 10">已确认|已完成</span>
                        <span v-if="scope.row.order_status == -1">无效订单</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <span class="control" @click="orderDetail(scope.row)">查看</span>
                        <span class="control" v-if="scope.row.order_status == 5" @click="orderCancel(scope.row)">取消</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="jump">
            <el-pagination class="jump-count" :page-size="pageSize" @current-change="handlePageChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" ref="pagination" :total="total"> </el-pagination>
        </div>
        <div style="margin-top: 20px;">
            <el-button class="order" @click="getMergeOrder" style="background: #ed6030"><img src="../../assets/images/alibaba/excel.png" />合并订单</el-button>
            <el-button class="order" @click="exportTable"><img src="../../assets/images/alibaba/excel.png" />导出Excel</el-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import {apiExportBookOrderList, apiExportOrderList, apiOrderList, apiYuOrderList, mergeOrder} from "@/api/order/order"
import orderMethod from "@/utils/orderMethod"
import { info, nickName, headImg } from "@/api/member/info"
import Config from "@/utils/config";
export default {
    name: "order_list",
    components: {},
    data: () => {
        return {
            select_all:false,
            payStatus: "all",
            orderStatus: "all_order",
            loading: true,
            currentPage: 1,
            pageSize: 8,
            total: 0,
            yes: true,
            orderList: [],
            selectOrders:[],
            checkOrders: [
                { label: "全部", value: "all" },
                { label: "未付款", value: "unpaid" },
                { label: "已付款", value: "waitsend" }
            ],
            orderStatusList: [
                { label: "全部", value: "all_order" },
                { label: "未确认", value: "unconfirmed" },
                { label: "未完成", value: "uncomplete" },
                { label: "部分完成", value: "partover" },
                { label: "已完成", value: "over" },
                { label: "无效订单", value: "invalid" }
            ],
            memberInfo: {
                all_order:"",
                unconfirmed: "", //未确认订单
                uncomplete: "", //未完成订单
                partover:"",//部分完成
                over:"",//已完成
                invalid:"",//无效订单

                unpaid: "", //未付款订单
                waitsend: "", //已付款


                favorites: "", //收藏夹商品数
                fivemonth: "", //近5个月完成订单
                allmoney: "", //已完成订单金额
                uncomplete_yu: "" //未完成预订单
            },
            keyword: "",
            topList: "all", //全部订单
            topConfirmed: "unter", //未确认
            topUnpaid: "unpaid", //未付款
            topUnshipped: "waitsend", //未发货
            topUncomplete: "uncomter" //未完成
        }
    },
    mixins: [orderMethod],
    created() {
        this.payStatus = this.$route.query.status || "all"
        this.orderStatus = this.$route.query.order_status || "all_order"
        this.keyword = this.$route.query.status
        this.getOrderList()
        this.getInfo()
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"])
    },
    methods: {
        getInfo() {
            info({order_type:5})
                .then((res) => {
                    if (res.code == 0) {
                        //全部
                        this.memberInfo.all_order   = res.data.membwe_order_status.all_order;
                        this.memberInfo.unconfirmed = res.data.membwe_order_status.unconfirmed
                        this.memberInfo.uncomplete = res.data.membwe_order_status.uncomplete
                        this.memberInfo.partover = res.data.membwe_order_status.partover
                        this.memberInfo.over = res.data.membwe_order_status.over
                        this.memberInfo.invalid = res.data.membwe_order_status.invalid

                        this.memberInfo.unpaid = res.data.membwe_order_status.wait_pay_order
                        this.memberInfo.unshipped = res.data.membwe_order_status.wait_fa_order
                        this.memberInfo.uncomplete_yu = res.data.membwe_order_status.wait_yu_que_order
                        this.memberInfo.favorites = res.data.membwe_order_status.collection_goods
                        this.memberInfo.fivemonth = res.data.membwe_order_status.five_wan_order
                        this.memberInfo.allmoney = res.data.membwe_order_status.finish_order_price
                    }
                    this.loading = false
                    console.log(res.message)
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                    console.log(err.message)
                })
        },
        getMergeOrder() {
            // console.log(this.checkOrders)
            let arr = []
            let order_merge = []

            if (this.selectOrders.length > 0) {
              this.selectOrders.forEach((item, index) => {
                if (item.order_status  != 5) {
                  console.log(123456789)
                  this.open()
                  return
                }
                arr.push(item.order_id)
              });
            }
            console.log(arr);
            let brr = []
            brr.order_id = arr
            // return false
            mergeOrder(brr)
                .then((res) => {
                    if (res.code == 0) {
                        this.getOrderList()
                    }
                    // this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        open() {
            this.$alert("仅支持订单状态为待审核的订单", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                    // this.$message({
                    //     type: "info",
                    //     message: `action: ${action}`
                    // })
                }
            })
        },
        async exportTable() {
          const _this = this;

          this.loading = true;
          let order_no = '';
          let tag = '';
          if (this.selectOrders.length > 0) {
            this.selectOrders.forEach((item, index) => {
              console.log("选中订单", item);
              order_no += tag + item.order_no;
              tag = ',';
            });
          }

          //调整为后端导出
          apiExportBookOrderList({
            keyword: this.keyword,
            page: this.currentPage,
            page_size: this.pageSize,
            order_status: this.orderStatus,
            paystatus: this.topList,
            consignee: this.consignee,
            order_no: order_no,
            export:true
          }).then((res) => {
            console.log("订单导出", res);
            if (res.code >= 0) {
              this.loading = false
              window.open(Config.imgDomain+res.data.path, '_blank');
            } else {
              this.$message({
                message: "未获取到订单信息",
                type: "warning",
                duration: 2000
              })
            }
          }).catch((err) => {
            this.loading = false
            this.$message.error({
              message: err.message,
              duration: 2000
            })
          })
          return;

          let xlsx = require("xlsx")
          let tableData = [
            ["订单号", "下单时间", "订单金额", "已交货金额", "未交货金额", "订单状态"] //导出表头
          ] // 表格表头
          if (this.select_all) {
            //全部导出
            let currentPage = 1;
            let pageSize = 10;
            //开始循环导出订单
            let total, export_total = 0;
            do {
              this.loading = true;
              await apiOrderList({
                page: currentPage,
                page_size: pageSize,
                paystatus: this.payStatus,
                order_status: this.orderStatus
              }).then((res) => {
                let list = []
                if (res.code == 0 && res.data) {
                  list = res.data.list
                  total = res.data.count
                }
                // 表格表头
                list.forEach((item, index) => {
                  let rowData = []
                  //状态描述
                  var status = '';
                  if(item.order_status == -1) {
                    status = '无效订单';
                  } else if(item.order_status == 5){
                    status = '未确认';
                  } else if(item.order_status == 6){
                    status = '已确认|未完成';
                  } else if(item.order_status == 7){
                    status = '已完成|未完成';
                  } else if(item.order_status == 11){
                    status = '已确认|部分完成';
                  } else if(item.order_status == 10){
                    status = '已确认|已完成';
                  }
                  //导出内容的字段
                  rowData = [item.order_no, _this.$util.timeStampTurnTime(item.create_time), parseFloat(item.goods_money), 0, parseFloat(item.pay_money), status]
                  tableData.push(rowData)
                  export_total++;
                })
                console.log("导出成功", [currentPage, export_total, total]);
                currentPage++;
                this.loading = false;
              }).catch((res) => {
                this.loading = false;
                console.log("导出失败!", res)
              })
            } while (export_total < total);
          } else {
            this.orderList.forEach((item, index) => {
              let rowData = []
              var status = '';
              if(item.order_status == -1) {
                status = '无效订单';
              } else if(item.order_status == 5){
                status = '未确认';
              } else if(item.order_status == 6){
                status = '已确认|未完成';
              } else if(item.order_status == 7){
                status = '已完成|未完成';
              } else if(item.order_status == 11){
                status = '已确认|部分完成';
              } else if(item.order_status == 10){
                status = '已确认|已完成';
              }
              //导出内容的字段
              rowData = [item.order_no, _this.$util.timeStampTurnTime(item.create_time), item.goods_money, 0, item.pay_money, status]
              tableData.push(rowData)
            })
          }
          let ws = xlsx.utils.aoa_to_sheet(tableData)
          let wb = xlsx.utils.book_new()
          xlsx.utils.book_append_sheet(wb, ws, "预订单列表") // 工作簿名称
          xlsx.writeFile(wb, "预订单列表.xlsx") // 保存的文件名
        },
        mergeTable() {
            this.pageSize = 30
            const _this = this
            // console.log(this.pageSize)
            let xlsx = require("xlsx")
            let tableData = [
                ["序号", "订单号", "下单时间", "订单应付金额", "实付金额", "订单状态"] //导出表头
            ] // 表格表头
            // console.log(this.orderList)
            this.orderList.forEach((item, index) => {
                let rowData = []
                //导出内容的字段
                rowData = [index + 1, item.order_no, _this.$util.timeStampTurnTime(item.create_time), item.goods_money, item.pay_money, item.order_status_name]
                tableData.push(rowData)
            })
            let ws = xlsx.utils.aoa_to_sheet(tableData)
            let wb = xlsx.utils.book_new()
            xlsx.utils.book_append_sheet(wb, ws, "合并订单") // 工作簿名称
            xlsx.writeFile(wb, "合并订单.xlsx") // 保存的文件名
        },
        //操作全选
        selectAll(){
          this.select_all = true;
        },
        // 选中订单
        setCheck(e) {
            this.selectOrders = e
        },
        // 取消订单
        orderCancel(order) {
            this.orderClose(order.order_id, () => {
                this.getOrderDetail()
            })
        },
        orderDetail(data) {
            switch (parseInt(data.order_type)) {
                case 2:
                    // 自提订单
                    this.$router.push({
                        path: "/member/order_detail_pickup",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                case 3:
                    // 本地配送订单
                    this.$router.push({
                        path: "/member/order_detail_local_delivery",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                case 4:
                    // 虚拟订单
                    this.$router.push({
                        path: "/member/order_detail_virtual",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                default:
                    this.$router.push({
                        path: "/member/order_detail",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
            }
        },
        imageError(orderIndex, goodsIndex) {
            this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage
        },
        // 全部订单
        getOrderList(order_status) {
            if (order_status) {
              this.orderStatus = order_status;
            }
            apiOrderList({
                keyword: this.keyword,
                page: this.currentPage,
                page_size: this.pageSize,
                order_status: this.orderStatus,
                paystatus: this.topList,
            })
                .then((res) => {
                    let list = []
                    if (res.code == 0 && res.data) {
                        list = res.data.list
                        this.total = res.data.count
                        console.log(this.total)
                    }
                    this.orderList = list
                    this.loading = false
                })
                .catch((res) => {
                    this.loading = false
                })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.refresh()
        },
        handlePageChange() {
          this.getOrderList()
        },
        refresh() {
            this.loading = true
            this.getOrderList()
        }
    }
}
</script>
<style lang="scss" scoped>
.act {
    color: #ed6030;
}
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .el-button {
            height: 40px;
            background-color: #2d9842;
            color: #ffffff;
            font-size: $ns-font-size-lg;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;

            img {
                width: 22px;
                height: 22px;
                margin-right: 5px;
            }
        }
        .switchTab {
            display: flex;
            align-items: center;

            .tab-item {
                font-size: $ns-font-size-lg;
                font-weight: 400;
                padding: 0 15px;
                border-right: 1px solid #e1e1e1;
                height: 20px;
                display: block;
                line-height: 20px;
                cursor: pointer;

                &:last-child {
                    border: 0;
                }
            }
            .number {
                color: #ed6030;
            }
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .form-item {
            display: flex;
            align-items: center;
            color: $ns-text-color-black;

            span {
                margin-right: 10px;
            }

            .search-btn {
                height: 32px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 10px;

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }

    .table {
        width: 100%;
        margin-top: 30px;

        .control {
            font-size: $ns-font-size-base;
            color: $base-color;
            cursor: pointer;

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    .el-pagination {
        margin-top: 30px;
    }
}
.jump {
    // text-align: right;
    // margin-top: 20px;
    .jump-count {
        // padding-right: 20px;
        float: left;
        margin-left: 80%;
        position: absolute;
        right: 0px;
    }

    .sure {
        margin: 0px;
        font-size: 13px;
        padding: 7px 10px;
        height: 28px;
        border-radius: 3px;
        background-color: #fff;
        color: #606266;
        line-height: 13px;
        margin-top: 32px;
        border: 1px solid #dcdfe6;
    }
}
.order {
    float: left;
    height: 40px;
    background-color: #2d9842;
    color: #ffffff;
    font-size: $ns-font-size-lg;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    img {
        width: 22px;
        height: 22px;
        margin-right: 5px;
    }
}
.top1 {
    width: 1240px !important;
}
.el-pagination:after, .el-pagination:before {
  display: table;
  content: "";
}
</style>
